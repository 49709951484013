// ----------------------------------------------------------------------

// const ROOTS = {
//   AUTH: '/auth',
//   DASHBOARD: '/dashboard',
// };

// ----------------------------------------------------------------------

export const routePaths = {
  fusionWeb: 'https://fusion.ng/',
  login: '/login',
  register: '/register',

  // AUTH
  auth: {
    jwt: {
      login: `/login`,
      register: `/register`,
      forgotPassword: '/forgot-password',
    },
  },
  explore: {
    publicPosts: `/`,
    publicCommunities: `/explore/public-communities`,
    publicShop: `/explore/shop`,
  },
  feeds: `/feeds`,
  communityDetail: `/community`,
  postDetail: `/posts`,
  shopDetail: `/events`,
  search: `/search`,
  notifications: `/notifications`,
  people: `/people`,
};
